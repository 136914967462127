import { useEffect, useState } from "react";
import style from "./style.scss";
import { kycDetailsRoutes } from "../../alias/kycRoutes";
import Modal from "../common/Modal";
import { Fragment } from "preact";
import { KYC_REDIRECTION_ROUTES_CONFIG } from "../../constants";
import { baseUrlGenerator } from "../../utils";
function Header({ userDetails }) {
	const [userHeaderDetails, setUserHeaderDetails] = useState(userDetails);
	const [showCallUs, setShowCallUs] = useState(false);
	const [showFaqs, setShowFaqs] = useState(false);
	const isAuthenticated = !!userDetails;
	const { extractedBaseUrl } = baseUrlGenerator();
	const openNav = () => {
		document.getElementById("mySidenav").style.width = "250px";
		$("body").addClass("dark-back");
		setTimeout(() => {
			$(".sidebar-close-btn").show();
		}, 300);
	};

	const closeNav = () => {
		document.getElementById("mySidenav").style.width = "0";
		$("body").removeClass("dark-back");
		$(".sidebar-close-btn").hide();
	};
	const scrollFunction = () => {
		if (
			document.body.scrollTop > 25 ||
			document.documentElement.scrollTop > 25
		) {
			$(".nav-div").addClass("navbar-white");
		} else {
			$(".nav-div").removeClass("navbar-white");
		}
	};

	const checkKycRoute = kycDetailsRoutes.map((item) => item.path);
	const rawWindowUrl = window.location.pathname.endsWith("/")
		? window.location.pathname.slice(0, -1)
		: window.location.pathname;
	const isKycRoute = checkKycRoute.includes(rawWindowUrl);
	useEffect(() => {
		if (window !== "undefined") {
			window.addEventListener("scroll", scrollFunction);
		}
		setUserHeaderDetails(userDetails);

		$(document).ready(() => {
			$(".collapse-menu").click(function() {
				$(this).toggleClass("backgrey");
			});
		});

		return () => {
			if (window !== undefined) {
				window.removeEventListener("scroll", scrollFunction);
			}
		};
	}, []);

	$(document).ready(() => {
		let hasNumber = /\d/;

		function forwardRedirection(search_value) {
			let search = search_value;
			$(".header-bond-search").val(search);
			let bond_details_url = "";

			if (search.toUpperCase().startsWith("INE") && hasNumber.test(search)) {
				//isin INE search
				bond_details_url =
					process.env.PREACT_APP_NAVIGATION_ENDPOINT +
					"bond-directory/12345/".replace(/12345/, search.toString());
				location.href = bond_details_url;
			} else if (
				search.toUpperCase().startsWith("IN") &&
				hasNumber.test(search)
			) {
				//isin IN search
				bond_details_url =
					process.env.PREACT_APP_NAVIGATION_ENDPOINT +
					"bond-directory/12345/".replace(/12345/, search.toString());
				location.href = bond_details_url;
			} else {
				// issuer search
				bond_details_url = `${
					process.env.PREACT_APP_NAVIGATION_ENDPOINT
				}bond-directory/?search=${encodeURIComponent(search)}`;
				location.href = bond_details_url;
			}
		}

		function autocompleteitem(inp, arr) {
			let a,
				b,
				i,
				e,
				val = inp.value;
			closeAllOptionList();
			if (!val) {
				return false;
			}
			a = document.createElement("DIV");
			a.setAttribute("id", `${inp.id}options-list`);
			a.setAttribute("class", "options-items");

			let screenWidth = $(window).width();
			if (screenWidth < 991) {
				$("#mobile-header-autocomplete-lists").append(a);
			} else {
				$("#header-autocomplete-lists").append(a);
			}

			for (i = 0; i < arr.length; i++) {
				if (arr[i].isin_search) {
					if (
						arr[i].name.substr(0, val.length).toUpperCase() == val.toUpperCase()
					) {
						var isItemAvailable =
							arr[i].name.substr(0, val.length).toUpperCase() ==
							val.toUpperCase();
						b = document.createElement("DIV");
						b.innerHTML = `<strong class='font-purple'>${arr[i].name.substr(
							0,
							val.length
						)}</strong>`;
						b.innerHTML += `${arr[i].name.substr(
							val.length
						)}<span class='no-show-mobile'></span>`;
						b.innerHTML += `<input type='hidden' value='${arr[i].slug}'>`;
						b.addEventListener("click", function(e) {
							this.value = this.getElementsByTagName("input")[0].value;
							forwardRedirection(this.getElementsByTagName("input")[0].value);
							closeAllOptionList();
						});
						a.appendChild(b);
					}
				} else if (
					arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()
				) {
					var isItemAvailable =
						arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase();
					b = document.createElement("DIV");
					b.innerHTML = `<strong class='font-purple'>${arr[i].substr(
						0,
						val.length
					)}</strong>`;
					b.innerHTML += `${arr[i].substr(
						val.length
					)}<span class='no-show-mobile'></span>`;
					b.innerHTML += `<input type='hidden' value='${arr[i]}'>`;
					b.addEventListener("click", function(e) {
						this.value = this.getElementsByTagName("input")[0].value;
						forwardRedirection(this.getElementsByTagName("input")[0].value);
						closeAllOptionList();
					});
					a.appendChild(b);
				}
			}

			if (isItemAvailable != true && val.length > 4) {
				e = document.createElement("div");
				e.setAttribute("class", "search-title-div");
				e.innerHTML =
					"<p class='cannot-find-title p-no-margin'>We can't find a bond with this ISIN/Issuer</p>";
				a.appendChild(e);
			}

			function closeAllOptionList(elmnt) {
				let x = document.getElementsByClassName("options-items");
				for (let i = 0; i < x.length; i++) {
					if (elmnt != x[i] && elmnt != inp) {
						x[i].parentNode.removeChild(x[i]);
					}
				}
			}
			document.addEventListener("click", (e) => {
				closeAllOptionList(e.target);
			});
		}

		function get_header_inventory_list(this1, search, is_issuer) {
			fetch(
				`${process.env.PREACT_APP_NAVIGATION_ENDPOINT}matching-securities/?search=${search}&is_issuer=${is_issuer}`
			)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.status === 200) {
						autocompleteitem(this1, data.object_list);
					} else {
						console.log(data.message);
					}
				})
				.catch((error) => {
					console.error("failed to fetch matching securities:", error);
				});
		}

		$(".header-bond-search").on("keyup", function(e) {
			let search = $(this).val();

			this.addEventListener("input", function(e) {
				autocompleteitem(this, []);
			});

			if (search.length <= 2) return false;

			if (search.toUpperCase().startsWith("INE") && hasNumber.test(search)) {
				get_header_inventory_list(this, search, false);
			} else if (
				search.toUpperCase().startsWith("IN") &&
				hasNumber.test(search)
			) {
				get_header_inventory_list(this, search, false);
			} else {
				get_header_inventory_list(this, search, true);
			}
		});

		$(".mobile-search").on("click", () => {
			if ($(".mobile-header-searchbar").is(":visible")) {
				$(".mobile-search").removeClass("isactive-mobile-search");

				$(".mobile-header-searchbar").slideUp("fast");
				$(".header-search-input").val("");
				setTimeout(() => {
					$(".nav-div").removeClass("nav-white");
				}, 100);
			} else {
				$(".mobile-search").addClass("isactive-mobile-search");

				$(".nav-div").addClass("nav-white");
				setTimeout(() => {
					$(".mobile-header-searchbar").slideDown("fast");
				}, 50);
				setTimeout(() => {
					$(".header-search-input").focus();
				}, 360);
			}
		});

		$(".mobile-search-cross").on("click", () => {
			$(".header-search-input").val("");
		});

		$(".header-search-input").on("input", function() {
			if (
				$(this)
					.val()
					.trim() !== ""
			) {
				$(this).addClass("input-active-color");
			} else {
				$(this).removeClass("input-active-color");
			}
		});
	});

	let isDomainTypePartner = false;

	if (
		window.location.href.indexOf(".com") > -1 &&
		window.location.href.indexOf("www.indiabonds") < 0
	) {
		isDomainTypePartner = true;
	}
	const myProfileUrl = ["I"].includes(userHeaderDetails?.type_of_user)
		? "/customer/profile/"
		: "/profile/basic-details";

	const kycRedirectUrl =
		KYC_REDIRECTION_ROUTES_CONFIG[userHeaderDetails?.type_of_user];
    const showRefer=userHeaderDetails?.type_of_user==="I";
    
	return (
		<>
			<div className="nav-div">
				<nav class={style.navbar}>
					<a href={`${extractedBaseUrl}`}>
						<img
							src="https://assets.indiabonds.com/react/ib-portfolio/india-bonds-logo.png"
							class={style.navbarLogo}
							alt="India Bonds"
						/>
					</a>
					{isDomainTypePartner ? (
						<div className={`${style.partnerLogoClassParent}`}>
							<img
								className={`${style.partnerLogoClass}`}
								src="https://ib-prod-media.s3.ap-south-1.amazonaws.com/sbicapsec_60296303-1045-450d-a5bb-43a45ac23c03.png"
							/>
						</div>
					) : (
						""
					)}
					{isKycRoute ? (
						<div class={style.navbarKycMenuWrapper} id="myNavbar">
							<ul className={`nav navbar-nav navbar-left ${style.navWeb}`}>
								{/* <li>
									<a class="navbar-link ">
										<button
											className={style.whiteBtn}
											onClick={() => setShowFaqs(true)}
										>
											<img
												width={15}
												src="https://ib-prod-media.s3.ap-south-1.amazonaws.com/web-site-assets/Group+21651.png"
											/>
											<span className={style.web}>FAQs</span>
										</button>
									</a>
								</li> */}
								<li class="">
									<a class="navbar-link">
										<button
											className={style.whiteBtn}
											onClick={() => setShowCallUs((prev) => !prev)}
										>
											<img
												width={15}
												src="https://ib-prod-media.s3.ap-south-1.amazonaws.com/web-site-assets/call.png"
											/>
											<span className={style.web}>Call Us</span>
										</button>
									</a>
								</li>
								<li>
									<a href="mailto:clientkyc@indiabonds.com" class="navbar-link">
										<button className={`${style.whiteBtn} ${style.pribtn}`}>
											<img src="https://assets.indiabonds.com/react/partner-program/email-us.png" />
											<span className={style.web}>Email Us</span>
										</button>
									</a>
								</li>
							</ul>
						</div>
					) : (
						<>
							<div class={style.navbarMenuWrapper} id="myNavbar">
								<ul class="nav navbar-nav navbar-right">
									{/* Products */}
									<li class="dropdown">
										<a
											id="navbar-products"
											class="dropdown-toggle navbar-link"
											data-toggle="dropdown"
											href="#"
										>
											Products{" "}
											<div class={`navCaret ${style.headerCaret}`}>&gt;</div>
										</a>
										<ul class="dropdown-menu">
											<li>
												<a
													id="navbar-products-bond-trading"
													href={`${extractedBaseUrl}/explore`}
													class="navbar-link"
												>
													Bond Trading
												</a>
											</li>
											{!isDomainTypePartner ? (
												<li>
													<a
														id="navbar-products-public-issue"
														href={`${extractedBaseUrl}/public-issue`}
														class="navbar-link"
													>
														Public Issue
													</a>
												</li>
											) : (
												""
											)}
											<li>
												<a
													id="navbar-products-gsec-sdl"
													href={`${extractedBaseUrl}/explore/government-bonds`}
													class="navbar-link"
												>
													G-Sec/SDL
												</a>
											</li>
											{!isDomainTypePartner ? (
												<Fragment>
													<li>
														<a
															id="navbar-products-sovereign-gold-bonds"
															href={`${extractedBaseUrl}/sgb/landing`}
															class="navbar-link"
														>
															Sovereign Gold Bonds
														</a>
													</li>
													<li>
														<a
															id="navbar-products-capital-gain-54ec"
															href={`${extractedBaseUrl}/capital-gain-54ec-bonds/`}
															class="navbar-link"
														>
															Capital Gain 54EC
														</a>
													</li>
													<li>
														<a
															id="navbar-products-bondcase"
															href={`${extractedBaseUrl}/bondcase/`}
															class="navbar-link"
														>
															BondCase
														</a>
													</li>
													<li>
														<a
															id="navbar-products-fixed-deposits"
															href={`${extractedBaseUrl}/explore/fixed-deposits/`}
															class="navbar-link"
														>
															Fixed Deposit
															<span class="menu-new-tag">New</span>
														</a>
														
													</li>
													{/* <li>
														<a
															id="navbar-products-debt-pms"
															href={`${extractedBaseUrl}/debt-pms/`}
															class="navbar-link"
														>
															Debt PMS
														</a>
													</li> */}
												</Fragment>
											) : (
												""
											)}
										</ul>
									</li>
									{/* tOOLS */}
									<li class="dropdown">
										<a
											id="navbar-tools"
											class="dropdown-toggle navbar-link"
											data-toggle="dropdown"
											href="#"
										>
											Tools
											<div class={`navCaret ${style.headerCaret}`}>&gt;</div>
										</a>
										<ul class="dropdown-menu">
											<li>
												<a
													id="navbar-bond-directory"
													href={`${extractedBaseUrl}/bond-directory`}
													title="Bond Directory"
													class="navbar-link"
												>
													Bond Directory
												</a>
											</li>
											<li>
												<a
													id="navbar-products-bond-calc"
													href={`${extractedBaseUrl}/bond-calculator`}
													class="navbar-link"
												>
													Bond Calculator
												</a>
											</li>
											<li id="navbar-my-prtfolio" class="navbar-link">
												<a
													href={`${extractedBaseUrl}/portfolio/dashboard`}
													class="navbar-link"
												>
													Portfolio Dashboard
												</a>
											</li>
											<li>
												<a
													id="navbar-products-form-15g15h"
													href={`${extractedBaseUrl}/form-15g-15h`}
													class="navbar-link"
												>
													Form 15 G / 15 H
												</a>
											</li>
										</ul>
									</li>
									{/* learn */}
                                    <li>
											<a
												id="navbar-bonduni"
                                                href={`${extractedBaseUrl}/bonduni`}
												title="BondUNi"
												class="navbar-link"
											>
												BondUNi
											</a>
										</li>
									{/* kyc */}
									{userHeaderDetails &&
									userHeaderDetails.ib_kyc_status === 4 ? (
										<li>
											<a
												id="navbar-kyc"
												href={`${extractedBaseUrl}${
													["I"].includes(userHeaderDetails?.type_of_user)
														? "/customer/profile/"
														: "/profile/basic-details"
												}`}
												title="KYC"
												class=" navbar-link"
											>
												KYC
											</a>
										</li>
									) : isAuthenticated ? (
										<li>
											<a
												id="navbar-kyc"
												href={kycRedirectUrl}
												title="KYC"
												class=" navbar-link"
											>
												<div className={`${style.kycPendingRed}`}>
													<img
														src="https://assets.indiabonds.com/react/ib-portfolio/alert.svg"
														className={`${style.kycAlertIcon}`}
													/>
													<span className={`${style.kycRedText}`}>KYC</span>
												</div>
											</a>
										</li>
									) : (
										<li>
											<a
												id="navbar-kyc"
												href={`${extractedBaseUrl}/customer/profile/`}
												title="KYC"
												class=" navbar-link"
											>
												KYC
											</a>
										</li>
									)}
									{/* Refer & Earn */}
									{/* {!isDomainTypePartner ? (
										isAuthenticated ? (
											<li>
												<a
													id="ref-earn-link-2"
													class="navbar-link"
													href={`${extractedBaseUrl}/referral_view/`}
												>
													Refer & Earn
												</a>
											</li>
										) : (
											<li>
												<a
													id="ref-earn-link-2"
													class="navbar-link"
													href={`${extractedBaseUrl}/refer_and_earn/`}
												>
													Refer & Earn
												</a>
											</li>
										)
									) : (
										""
									)} */}

									{/* Partners */}
									{!isDomainTypePartner ? (
										<li>
											<a
												id="partners-link"
												class="navbar-link"
												href="https://partner.indiabonds.com/"
											>
												Partners
											</a>
										</li>
									) : (
										""
									)}
									{/* more  */}
									<li class="dropdown">
										<a
											id="navbar-more"
											class="dropdown-toggle navbar-link"
											data-toggle="dropdown"
											href="#"
										>
											More{" "}
											<div
												class={`navCaret ${`navCaret ${style.headerCaret}`}`}
											>
												&gt;
											</div>
										</a>
										<ul class="dropdown-menu">
											{!isDomainTypePartner ? (
												<Fragment>
													<li class="about-header-icon">
														<a
															id="navbar-more-about-us"
															href={`${extractedBaseUrl}/about-us`}
															className={`navbar-link ${style.navbarHoverIconLink}`}
														>
															About Us
														</a>
													</li>
													<li class="careers-header-icon">
														<a
															href={`${extractedBaseUrl}/careers`}
															className={`navbar-link ${style.navbarHoverIconLink}`}
														>
															Careers
														</a>
													</li>
													<li class="contact-header-icon">
														<a
															id="navbar-more-media"
															href={`${extractedBaseUrl}/media-updates`}
															className={`navbar-link ${style.navbarHoverIconLink}`}
														>
															Media
														</a>
													</li>
												</Fragment>
											) : (
												""
											)}
											<li class="contact-header-icon">
												<a
													id="navbar-more-contact-us"
													href={`${extractedBaseUrl}/contact-us`}
													className={`navbar-link ${style.navbarHoverIconLink}`}
												>
													Contact Us
												</a>
											</li>
											<li class="investors-header-icon">
												<a
													id="navbar-more-investor-grievance"
													href="https://assets.indiabonds.com/Investor+Grievance+Mechanism_IndiaBonds.pdf"
													target="_blank"
													className={`navbar-link ${style.navbarHoverIconLink}`}
													rel="noreferrer"
												>
													Investor Grievance
												</a>
											</li>
										</ul>
									</li>
									{/* search */}
									<li class="header-searchbar">
										<div
											id="home-page-second-section-search-input"
											class="header-search-wrapper"
										>
											<div
												class="mobile-search-input-wrapper"
												id="mobile-search-wrapper"
											>
												<input
													type="text"
													autocomplete="off"
													class="form-control header-bond-search header-search-input"
													placeholder="Search any bond by ISIN or Issuer Name"
												/>
											</div>
											<div
												id="header-autocomplete-lists"
												class="header-autocomplete-items"
											/>
										</div>
									</li>

									{/* profile */}
									{!userHeaderDetails ? (
										<span class={style.loginSignupCta}>
											<li>
												<a
													id="navbar-login"
													title="Login"
													href={`${extractedBaseUrl}/customer/login/?next=`}
													class={`navbar-link ${style.loginBtn}`}
													style="padding-left: 12px;padding-right:14px"
												>
													Login
												</a>
											</li>
											<li>
												<a
													id="navbar-signup"
													title="Sign Up"
													href={`${extractedBaseUrl}/customer/register/?next=`}
													class="navbar-link no-top-pad"
													style="margin-top: -5px;padding-top: 0;padding-right:0"
												>
													<button
														class={`primary-btn sign-up-btn ${style.signBtn}`}
													>
														Sign Up
													</button>
												</a>
											</li>
										</span>
									) : (
										<li style="margin-left: auto;">
											<div class="user-profile-div dropdown">
												<div class="user-pic">
													<img
														src="https://assets.indiabonds.com/react/ib-portfolio/user-black.svg"
														alt="user"
													/>
												</div>
												<p class="p-no-margin user-name">
													{userHeaderDetails.type_of_user === "I"
														? userHeaderDetails.first_name
														: userHeaderDetails.pan_full_name}
												</p>
												{userHeaderDetails.ib_kyc_status === 4 &&
												userHeaderDetails.corporateBondTrading?.toLowerCase() ===
													"approved" ? (
													<img
														src="https://assets.indiabonds.com/react/ib-portfolio/green-tick.png"
														class="user-green-tick"
														alt="user"
														style="width: 15px;margin-right: 10px"
													/>
												) : (
													""
												)}
												<div class="dropdown">
													<a
														class="dropdown-toggle navbar-link"
														data-toggle="dropdown"
														href="#"
													>
														<div class={`navCaret ${style.profileCaret}`}>
															&gt;
														</div>
													</a>
													<ul class="dropdown-menu profile-menu-dropdown">
														{userHeaderDetails.ib_kyc_status === 4 ? (
															<li className={`${style.kycVerifiedGreen}`}>
																<a href="#" class="navbar-link">
																	KYC Verified
																</a>
															</li>
														) : (
															""
														)}

														<li class="about-header-icon">
															<a
																href={`${extractedBaseUrl}${myProfileUrl}`}
																class="navbar-link"
															>
																My Profile
															</a>
														</li>
														<li class="portfolio-header-icon">
															<a
																href={`${extractedBaseUrl}/portfolio/dashboard`}
																class="navbar-link"
															>
																My Portfolio
															</a>
														</li>
														{userHeaderDetails.pms_code ? (
															<li class="">
																<a
																	href={`https://pms.akgroup.co.in/#/?pmscode=${userHeaderDetails.pms_code}`}
																	target="_blank"
																	class="navbar-link"
																	rel="noreferrer"
																>
																	My Debt PMS
																</a>
															</li>
														) : (
															""
														)}
														<li class="">
															<a
																href={`${extractedBaseUrl}/customer/order-book`}
																class="navbar-link"
															>
																My Orders
															</a>
														</li>
														<li class="">
															<a
																href={`${extractedBaseUrl}/sign-out`}
																class="navbar-link"
																id="logout_block"
															>
																Logout
															</a>
														</li>
													</ul>
												</div>
											</div>
										</li>
									)}
								</ul>
							</div>

							<div
								class={`collapse-block hidden-lg hidden-md ${style.loginSignupCta}`}
							>
								{!userHeaderDetails.is_authenticated ? (
									<span>
										<span style="font-size: 13px;color: #292828;font-weight: 600;margin-right: 8px;">
											<a
												href={`${extractedBaseUrl}/customer/login/?next=`}
												style="color: #292828;"
											>
												Login
											</a>
										</span>
										<span style="margin-right: 8px;">
											<a
												title="Sign Up"
												href={`${extractedBaseUrl}/customer/register/?next=`}
												class="navbar-link no-top-pad  no-show-desktop"
												style="margin-top:-5px"
											>
												<button class="primary-btn sign-up-btn campaign-sign-up-btn">
													Sign Up
												</button>
											</a>
										</span>
									</span>
								) : (
									""
								)}
								<div class={`mobile-search ${style.mobileSearchTrigger}`}>
									<img
										class={style.mobileSearchIcon}
										src="https://assets.indiabonds.com/react/ib-portfolio/mobile_black_search_icon.svg"
									/>
								</div>

								<span class={style.sidenavTrigger} onClick={openNav}>
									<img
										class={style.menuHamburger}
										src="https://assets.indiabonds.com/react/ib-portfolio/mobile_menu_icon.svg"
									/>
									{userHeaderDetails &&
									userHeaderDetails.ib_kyc_status !== 4 ? (
										<img
											class={style.sidenavTriggerDot}
											src="https://assets.indiabonds.com/react/ib-portfolio/alert.svg"
										/>
									) : null}
								</span>
							</div>
						</>
					)}
				</nav>

				<div class="mobile-header-searchbar" style="display: none;">
					<div
						id="home-page-second-section-search-input"
						class="mobile-input-group"
					>
						<div class="mobile-search-input-wrapper" id="mobile-search-wrapper">
							<input
								type="text"
								autocomplete="off"
								class="form-control header-bond-search header-search-input"
								placeholder="Search any bond by ISIN or Issuer Name"
							/>
							<img
								class="mobile-search-cross"
								src="https://assets.indiabonds.com/react/ib-portfolio/black_cross_icon.svg"
							/>
						</div>
						<div
							id="mobile-header-autocomplete-lists"
							class="header-autocomplete-items"
						/>
					</div>
				</div>
			</div>
			<div
				id="mySidenav"
				class="sidenav"
				style="background: radial-gradient(circle at 0px 566px, transparent 23px, rgb(255, 255, 255) 0px);"
			>
				<div class="sidenav-content">
					<div class="sidebar-close-btn" style="display:none;">
						{" "}
						<btn onClick={closeNav}>&times;</btn>
					</div>
					{userHeaderDetails ? (
						<div class="profile-name-div" data-toggle="dropdown">
							<span href="" class="profile-anchor">
								<img
									src="https://assets.indiabonds.com/react/ib-portfolio/user-black.svg"
									class="user-icon"
									alt="user"
								/>
								<span class="user-name-sidenav">
									{userHeaderDetails.type_of_user === "I"
										? userHeaderDetails.first_name
										: userHeaderDetails.pan_full_name}
								</span>
								{userHeaderDetails.ib_kyc_status === 4 &&
								userHeaderDetails.corporateBondTrading?.toLowerCase() ===
									"approved" ? (
									<img
										src="https://assets.indiabonds.com/react/ib-portfolio/green-tick.png"
										class="user-green-tick"
										alt="user"
										style="width: 15px;margin-right: 10px;margin-left: 5px;"
									/>
								) : (
									""
								)}
							</span>
							<div class="dropdown sidenav-profile-dd">
								<div class="goto-profile-div dropdown-toggle">
									<img
										src="https://assets.indiabonds.com/react/ib-portfolio/right-angle-purple.png"
										class="goto-profile-icon"
										alt="user"
									/>
								</div>
							</div>
						</div>
					) : (
						<span style="display: flex;align-items: center">
							<span style="margin-right: 15px;">
								<a
									title="Sign Up"
									href={`${extractedBaseUrl}/customer/register/?next=`}
									class="navbar-link no-top-pad  no-show-desktop"
									style="margin-top:-5px"
								>
									<button class="primary-btn sign-up-btn campaign-sign-up-btn mobile-nav">
										Sign Up
									</button>
								</a>
							</span>
							<span>
								<a
									title="Explore"
									href={`${extractedBaseUrl}/explore/`}
									style="padding-left: 10px;"
								>
									<img
										class="header-search-icon no-show-desktop"
										src="https://assets.indiabonds.com/react/ib-portfolio/search-icon.svg"
										alt="search"
										style="padding-right: 0;position: relative;left: 0;bottom: 2px;"
									/>{" "}
									Search
								</a>
							</span>
						</span>
					)}
					<ul class="dropdown-menu dropdown-menu-profile">
						<li>
							<a href={`${extractedBaseUrl}${myProfileUrl}`}>
								<div class="logout-anchor p-no-margin " id="my_orders_block">
									My Profile
								</div>
							</a>
						</li>
						<li>
							<a href={`${extractedBaseUrl}/customer/order-book`}>
								<div class="logout-anchor p-no-margin " id="my_orders_block">
									My Orders
								</div>
							</a>
						</li>
						<li>
							<a href={`${extractedBaseUrl}/portfolio/dashboard`}>
								<div class="logout-anchor p-no-margin " id="my_orders_block">
									My Portfolio
								</div>
							</a>
						</li>
						<li>
							<a href={`${extractedBaseUrl}/sign-out`}>Logout</a>
						</li>
					</ul>
					<hr class="hr-mobile-menu profile-menu-space" />
					{/* <a href={`${process.env.PREACT_APP_NAVIGATION_ENDPOINT}explore`} class="">Explore</a> */}

					<span
						class="font-purple ib-position-relative installPWA hide"
						id="installIbBoltDrawer"
						style="padding: 8px 8px 8px 32px;text-decoration: none;font-size: 15px;color: #292828;font-weight: 600;display: block;transition: .3s;"
					>
						Install IB Bolt! <span class="ib-new-bolt-tag">New</span>
					</span>
					{/* <a href={`${process.env.PREACT_APP_NAVIGATION_ENDPOINT}how-to-buy-bonds`} class="">How it works?</a> */}

					{/* product */}
					<div class="collapse-menu">
						<a data-toggle="collapse" data-target="#products-dd" class=" ">
							<span>Products</span>{" "}
							<span>
								<img
									class="sidenav-caret"
									src="https://assets.indiabonds.com/react/ib-portfolio/down-angle-purple.png"
								/>
							</span>
						</a>
						<div id="products-dd" class="collapse">
							<a href={`${extractedBaseUrl}/explore`} class="sub-menu">
								Bond Trading
							</a>
							{!isDomainTypePartner ? (
								<a href={`${extractedBaseUrl}/public-issue`} class="sub-menu">
									Public Issue
								</a>
							) : (
								""
							)}
							<a
								href={`${extractedBaseUrl}/explore/government-bonds`}
								class="navbar-link"
							>
								G-Sec/SDL
							</a>
							{!isDomainTypePartner ? (
								<Fragment>
									<a href={`${extractedBaseUrl}/sgb/landing`} class="sub-menu">
										Sovereign Gold Bonds
									</a>
									<a
										href={`${extractedBaseUrl}/capital-gain-54ec-bonds/`}
										class="navbar-link"
									>
										Capital Gain 54EC
									</a>
									<a
										href={`${extractedBaseUrl}/bondcase/`}
										class="navbar-link"
									>
										BondCase
									</a>
									<a
										href={`${extractedBaseUrl}/explore/fixed-deposits/`}
										class="navbar-link"
									>
										Fixed Deposit
										<span class="menu-new-tag">New</span>
									</a>
									{/* <a href={`${extractedBaseUrl}/debt-pms`} class="sub-menu">
										<span class="new-tag-span">Debt PMS</span>
									</a> */}
								</Fragment>
							) : (
								""
							)}
						</div>
					</div>
					{/* tools */}
					<div class="collapse-menu">
						<a data-toggle="collapse" data-target="#tools-dd" class=" ">
							<span>Tools</span>{" "}
							<span>
								<img
									class="sidenav-caret"
									src="https://assets.indiabonds.com/react/ib-portfolio/down-angle-purple.png"
								/>
							</span>
						</a>
						<div id="tools-dd" class="collapse">
							<a href={`${extractedBaseUrl}/bond-directory`} class="sub-menu">
								Bond Directory
							</a>
							<a href={`${extractedBaseUrl}/bond-calculator`} class="sub-menu">
								Bond Calculator
							</a>
							<a href={`${extractedBaseUrl}/explore`} class="sub-menu">
								Bond Trading
							</a>
							<a href={`${extractedBaseUrl}/form-15g-15h`} class="sub-menu">
								<span class="new-tag-span">Form 15 G / 15 H</span>
							</a>
						</div>
					</div>
					{/* learn */}
				    <a
                        href={`${extractedBaseUrl}/bonduni`}
                        title="BondUNi"
                        class="navbar-link"
                    >
                        <span>BondUNi</span>
                    </a>
					{/* kyc */}
					{userHeaderDetails && userHeaderDetails.ib_kyc_status === 4 ? (
						<a
							href={`${extractedBaseUrl}${
								["I"].includes(userHeaderDetails?.type_of_user)
									? "/customer/profile/"
									: "profile/basic-details"
							}`}
							title="KYC"
							class="navbar-link"
						>
							<span>KYC</span>
						</a>
					) : isAuthenticated ? (
						<a
							href={
								KYC_REDIRECTION_ROUTES_CONFIG[userHeaderDetails?.type_of_user]
							}
							title="KYC"
							class="kyc-pending-back navbar-link"
						>
							<span>KYC</span>
							<span class="pending-text-mobile">Pending</span>
						</a>
					) : (
						<a
							href={`${extractedBaseUrl}/customer/profile`}
							title="KYC"
							class="navbar-link"
						>
							<span>KYC</span>
						</a>
					)}

					{/* refer and earn */}
					{/* {!isDomainTypePartner ? (
						<a href={`${extractedBaseUrl}/referral_view`}>
							<span>Refer &amp; Earn</span>
						</a>
					) : (
						""
					)} */}

					{/* partners  */}
					{!isDomainTypePartner ? (
						<a href="https://partner.indiabonds.com">
							<span class="partners-nav">
								Partners
							</span>
						</a>
					) : (
						""
					)}

					{/* more */}
					<div class="collapse-menu">
						<a data-toggle="collapse" data-target="#more-dd" class="">
							<span>More</span>{" "}
							<span>
								<img
									class="sidenav-caret"
									src="https://assets.indiabonds.com/react/ib-portfolio/down-angle-purple.png"
								/>
							</span>
						</a>
						<div id="more-dd" class="collapse">
							{!isDomainTypePartner ? (
								<Fragment>
									<a href={`${extractedBaseUrl}/about-us`}>About Us</a>
									<a href={`${extractedBaseUrl}/careers`}>Careers</a>
									<a href={`${extractedBaseUrl}/media-updates`}>Media</a>
								</Fragment>
							) : (
								""
							)}
							<a href={`${extractedBaseUrl}/contact-us`}>Contact Us</a>
							<a
								href="https://assets.indiabonds.com/Investor+Grievance+Mechanism_IndiaBonds.pdf"
								target="_blank"
								rel="noreferrer"
							>
								Investor Grievance
							</a>
						</div>
					</div>

					<div class="login-div" />
					<hr class="hr-mobile-menu" />
					<div class="mobile-menu-bottom">
						<a href={`${extractedBaseUrl}/privacy-policy`} class="">
							Privacy Policy
						</a>
						<a href={`${extractedBaseUrl}/terms-and-conditions`} class="">
							Terms &amp; Conditions
						</a>
						<div class="social-div">
							<a
								href="https://www.facebook.com/IndiaBondsOfficial/"
								target="_blank"
								rel="nofollow noreferrer"
							>
								<img
									loading="lazy"
									src="https://assets.indiabonds.com/react/ib-portfolio/fb.png"
									alt="facebook logo"
								/>
							</a>
							<a
								href="https://twitter.com/india_bonds"
								target="_blank"
								rel="nofollow noreferrer"
							>
								<img
									loading="lazy"
									src="https://assets.indiabonds.com/react/ib-portfolio/twitter.png"
									alt="twitter logo"
								/>
							</a>
							<a
								href="https://www.linkedin.com/company/indiabonds"
								target="_blank"
								rel="nofollow noreferrer"
							>
								<img
									loading="lazy"
									src="https://assets.indiabonds.com/react/ib-portfolio/linkedin.png"
									alt="linkedin logo"
								/>
							</a>
							<a
								href="https://www.instagram.com/indiabonds/"
								target="_blank"
								rel="nofollow noreferrer"
							>
								<img
									loading="lazy"
									src="https://assets.indiabonds.com/react/ib-portfolio/instagram.png"
									alt="instagram logo"
								/>
							</a>
							<a
								href="https://www.youtube.com/channel/UC-DE1oUSTBMXJlp2IyFa7Ug"
								target="_blank"
								rel="nofollow noreferrer"
							>
								<img
									loading="lazy"
									src="https://assets.indiabonds.com/react/ib-portfolio/youtube-blue.png"
									alt="YouTube logo"
								/>
							</a>
						</div>
					</div>
				</div>
			</div>

			<Modal isOpen={showCallUs} closeModal={() => setShowCallUs(false)} fullScreen>
				<div className={style.callUsContainer}>
					<div className={style.modalHeading}>Call Us</div>
                    <div className={style.callBox}>
					<a href="tel:080-6919 9888" className={style.listItem}>
						<img
							src="https://assets.indiabonds.com/react/partner-program/call-blue.svg"
							alt=""
						/>
						080-6919 9888
					</a>
					<a
						href="//api.whatsapp.com/send?phone=918828135261"
						target="_blank"
						className={style.listItem}
						rel="noreferrer"
					>
						<img
							src="https://dev-kycapp.indiabonds.com/static/customer/leo9/img/whatsapp.png"
							alt=""
						/>
						+91-88281 35261
					</a>
					<a
						href="//api.whatsapp.com/send?phone=917208919179"
						target="_blank"
						className={style.listItem}
						rel="noreferrer"
					>
						<img
							src="https://dev-kycapp.indiabonds.com/static/customer/leo9/img/whatsapp.png"
							alt=""
						/>
						+91-72089 19179
					</a>
                    </div>
				</div>
			</Modal>

			<Modal isOpen={showFaqs} closeModal={() => setShowFaqs(false)}>
				<div className={style.faqModal}>
					<h3 className={style.faqHeader}>FAQs</h3>
					<div style="border-top: 1px solid #eee; margin-bottom:10px" />
					<div className={style.faqContainer}>
						<div style="margin-right:25px">
							<div>
								<strong>1) Details required for doing eKYC</strong>
							</div>
							<ol>
								<li style="margin-top:5px; text-align:justify">
									You shall require your PAN, Aadhaar, Bank Account (IFSC Code,
									Bank Account Number) and Demat Account Details (you will have
									to keep PAN Numbers of all the Holder/s and DP ID & Client ID
									[incase of NSDL] and Beneficiary ID [incase of CDSL] for doing
									eKYC.
								</li>
							</ol>
							<div style="margin-top:15px">
								<strong>2) Email Verification through OTP</strong>
							</div>
							<ol>
								<li style="margin-top:5px; text-align:justify">
									You shall have to verify your email id by entering OTP send by
									us on your email id.
								</li>
							</ol>
							<div style="margin-top:15px">
								<strong>3) Can I change my email id ?</strong>
							</div>
							<ol>
								<li style="margin-top:5px; text-align:justify">
									Yes - you can change your email id before verification.
									However the same should be unique in our KYC database.
								</li>
							</ol>
							<div style="margin-top:15px">
								<strong>4) Verification of PAN - Aadhaar;</strong>
							</div>
							<ol>
								<li style="margin-top:5px; text-align:justify">
									You shall have to enter PAN - Date of Birth and last 4 digits
									of your Aadhaar Number.
								</li>
								<li style="margin-top:5px; text-align:justify">
									For initiating eKYC process you shall have to confirm that you
									are:
									<ol>
										<li style="margin-bottom:5px">
											<strong>a.</strong> I am an Indian Citizen and Tax
											resident of India only. I am neither politically exposed
											or not related to one.
										</li>
										<li>
											<strong>b.</strong> I have read, understood, and hereby
											agree to abide by Terms of Use.
										</li>
									</ol>
								</li>
								<li style="margin-top:5px; text-align:justify">
									Reasons of failure / rejection:
									<ol>
										<li style="margin-bottom:5px">
											<strong>a.</strong> Date of Birth mismatch with PAN
											database
										</li>
										<li style="margin-bottom:5px">
											<strong>b.</strong> Last 4 digits of Aadhaar mismatch with
											PAN-Aadhaar combination in PAN database.
										</li>
										<li>
											<strong>c.</strong> PAN-Aadhaar not seeded
										</li>
									</ol>
								</li>
							</ol>
							<div style="margin-top:15px">
								<strong>5) Name Mismatch-between</strong> a) PAN Aadhaar, b) PAN
								and Name as per Bank, c) eSign
							</div>
							<ol>
								<li style="margin-top:5px; text-align:justify">
									If there is material mismatch in your name as stated above,
									then the system shall not allow you to proceed further. In
									such a scenario you can Email us, and we shall guide you
								</li>
							</ol>
							<div style="margin-top:15px">
								<strong>6) How many Bank accounts I can link?</strong>
							</div>
							<ol>
								<li style="margin-top:5px; text-align:justify">
									You can link maximum 5 Bank accounts (where you mandatorily
									must be the first holder) and out of which you must select any
									1 Bank as your Default Bank
								</li>
								<li style="margin-top:5px; text-align:justify">
									<strong>Note:</strong> On entering your Bank account details
									we shall be verifying the same through Penny Drop Process
								</li>
							</ol>
							<div style="margin-top:15px">
								<strong>7) How many Demat accounts I can link?</strong>
							</div>
							<ol>
								<li style="margin-top:5px; text-align:justify">
									You can link maximum 5 Demat accounts (where you mandatorily
									must be the first holder) and out of which you must select any
									1 Demat account as your Default Demat account
								</li>
								<li style="margin-top:5px; text-align:justify">
									<strong>Note:</strong> On entering your Demat account details
									we shall be verifying the same with Depositories
								</li>
							</ol>
							<div style="margin-top:15px">
								<strong>
									8) Is eSigning of KYC form is mandatory to complete the eKYC
									process?
								</strong>
							</div>
							<ol>
								<li style="margin-top:5px; text-align:justify">
									Yes - You will have to accept the Online Declaration and give
									consent for eSign the KYC form
								</li>
							</ol>
							<div style="margin-top:15px">
								<strong>
									9) Whether facility for downloading of KYC form is available
									(before and after eSign)
								</strong>
							</div>
							<ol>
								<li style="margin-top:5px; text-align:justify">
									Yes - You can download the KYC form before initiating eSign
									and post eSign. Further, we shall also be mailing you the eKYC
									form to your registered email id
								</li>
							</ol>
							<div style="margin-top:15px">
								<strong>10) Intimation of completion of my KYC</strong>
							</div>
							<ol>
								<li style="margin-top:5px; text-align:justify">
									On your submission of eSign KYC form, we shall be verifying
									the KYC documents and we shall intimate to you about
									acceptance or hold of your KYC with us. In case of KYC kept on
									hold our representative shall get in touch with you through
									email / mobile
								</li>
							</ol>
							<div style="margin-top:15px">
								<strong>
									11) Adding of additional Bank and Demat accounts (post doing
									KYC) - subject to maximum of 5 Banks / Demat
								</strong>
							</div>
							<ol>
								<li style="margin-top:5px; text-align:justify">
									Post doing KYC with us if you want to add any additional Bank
									/ Demat (maximum upto 5) you will have to mail to us from your
									registered email id - stating your Name & PAN with a request
									to add Bank / Demat account and provide KYC document for the
									same. The same are stated here-in below:
									<ol>
										<li style="margin-bottom:5px">
											<strong>a)</strong> For addition of Bank account: the scan
											image of cancelled self-certified photocopy of the
											personalized cheque (which should display your Name [as
											first holder], IFSC code, Bank account number or
											self-certified photocopy Bank Statement / Bank Passbook
											giving the said details
										</li>
										<li style="margin-bottom:5px">
											<strong>b)</strong> For addition of Demat account: You can
											submit to us self-certified photocopy of Client Master
											List (CML) or Demat Statement or Consolidated Account
											Statement issued by Depositories (NSDL / CDSL).
										</li>
									</ol>
								</li>
							</ol>
							<div style="margin-top:15px">
								<strong>
									12) When can I start investing in Bonds through IndiaBonds?
								</strong>
							</div>
							<ol>
								<li style="margin-top:5px; text-align:justify">
									On completion and acceptance of your KYC documents you shall
									be able to invest in Bonds through IndiaBonds
								</li>
							</ol>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default Header;
