export default class Utils {
	/* Save and retrieve session Cookie */
	static getCookie(name) {
		var value = "; " + document.cookie;
		var parts = value.split("; " + name + "=");
		if (parts.length === 2)
			return parts
				.pop()
				.split(";")
				.shift();
	}

	static setCookie(key, value, expiry, domain, path) {
		document.cookie =
			key +
			"=" +
			value +
			";expires=" +
			expiry +
			";domain=" +
			domain +
			";path=" +
			path;
	}

	static setSessionCookie(sessionId) {
		if (sessionId) {
			var now = new Date();
			var minutes = 30;
			now.setTime(now.getTime() + minutes * 60 * 1000);
			document.cookie =
				"sessionId=" + sessionId + ";expires=" + now.toUTCString() + "; path=/";
		}
	}

	static getSessionCookie() {
		return this.getCookie(process.env.PREACT_APP_SESSION_COOKIE);
	}
}

export const getCookie = (name) => {
	var value = "; " + document.cookie;
	var parts = value.split("; " + name + "=");
	if (parts.length === 2)
		return parts
			.pop()
			.split(";")
			.shift();
};

export const setLocalStorage = (key, value) => {
	try {
		localStorage.setItem(key, value);
	} catch (e) {}
};

export const getLocalstorage = (key) => {
	try {
		return localStorage.getItem(key);
	} catch (e) {}
};

export const removeLocalStorage = (key) => {
	try {
		localStorage.removeItem(key);
	} catch (e) {}
};

export const clearLocalstorage = () => {
	try {
		localStorage.clear();
	} catch (e) {}
};

export const setSessionStorage = (key, value) => {
	try {
		sessionStorage.setItem(key, value);
	} catch (e) {}
};

export const getSessionStorage = (key) => {
	try {
		return sessionStorage.getItem(key);
	} catch (e) {}
};

export const removeSessionStorage = (key) => {
	try {
		sessionStorage.removeItem(key);
	} catch (e) {}
};

export const clearSessionStorage = () => {
	try {
		sessionStorage.clear();
	} catch (e) {}
};

export const onRedirect = () => {
	window.location.href = `${process.env.PREACT_APP_NAVIGATION_ENDPOINT}`;
};

export const capitalizeFirstLetter = (str) => {
	return str[0].toUpperCase() + str.slice(1);
};

export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this,
			args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

export const getFormatAmount = (amount, decimalPlaces = 2) => {
	const formattedNumber = Number(amount || 0).toFixed(decimalPlaces);
	const formattedAmount = parseFloat(formattedNumber).toLocaleString("en-IN", {
		minimumFractionDigits: decimalPlaces,
	});

	return formattedAmount;
};

export const getShortFormatAmount = (acount, digits = 0) => {
	return Intl.NumberFormat("en-IN", {
		notation: "compact",
		maximumFractionDigits: digits,
	}).format(acount);
};

export const maskPhoneNumber = (phoneNumber) => {
	if (
		!phoneNumber ||
		typeof phoneNumber !== "string" ||
		phoneNumber.length < 5
	) {
		return phoneNumber;
	}

	var firstPart = phoneNumber.slice(0, 2);
	var lastPart = phoneNumber.slice(-3);

	var maskedPart = "X".repeat(phoneNumber.length - 5);

	return firstPart + maskedPart + lastPart;
};

export const maskEmail = (email) => {
	const [localPart, domain] = email.split("@");
	const maskedLocalPart =
		localPart.slice(0, 3) + "x".repeat(Math.max(0, localPart.length - 3));
	const maskedEmail = maskedLocalPart + "@" + domain;
	return maskedEmail;
};

export const checkRouteAccess = (routes) => {
	const routesUrl = routes.map((item) => item.path);
	const rawWindowUrl = window.location.pathname.endsWith("/")
		? window.location.pathname.slice(0, -1)
		: window.location.pathname;
	const result = routesUrl.some((url) => url.includes(rawWindowUrl));
	return result;
};

export const baseUrlGenerator = () => {
	const extractedUrl =
		window.location.href.indexOf(".com") > -1
			? `${window.location.href.split(".com")[0]}.com`
			: window.location.href;

	const extractedBaseUrl =
		process.env.PREACT_APP_ENV_NAME === "local"
			? process.env.PREACT_APP_NAVIGATION_ENDPOINT
			: extractedUrl;

	return { extractedBaseUrl };
};
