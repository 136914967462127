import { Router, route } from "preact-router";
import AsyncRoute from "preact-async-route";
import {
	portFolioSummaryLandingRoute,
	portfolioRoutes,
	portFolioUploadRoute,
} from "../alias/portfolioRoutes";
import { pageNotFoundRoute } from "../alias/commonRoutes";
import NotFound from "../routes/page-not-found";
import Redirect from "./redirect";
import Spinner from "./spinner/spinner";
import Header from "./header";
import Footer from "./footer";
import { getUserDetails } from "../services/authentication";
import { mediaRoutes } from "../alias/mediaRoutes";
import { useEffect, useState } from "react";
import { kycDetailsRoutes } from "../alias/kycRoutes";
import { sgbRoutes } from "../alias/sgbRoutes";
import { nonIndividualProfileRoutes } from "../alias/profileRoutes";
import { USER_ACCOUNT_TYPE_ACCESS_ROUTES } from "../constants";
import { checkRouteAccess } from "../utils";

function App() {
	const [userDetails, setUserDetails] = useState("");
	const [overidingClass, setOveridingClass] = useState("");

	const handleRoute = (e) => {
		const { current } = e || {};
		const { props: urlProps } = current || {};
		const { path } = urlProps || {};

		if (path === portFolioUploadRoute.path) {
			setOveridingClass("portfolioUploadOverride");
		}
	};

	useEffect(() => {
		getUserDetails().then((response) => {
			let responseData = response.data;
			responseData["is_authenticated"] = true;
			setUserDetails(responseData);

			const isNonIndividualKycRoute = checkRouteAccess(kycDetailsRoutes);

			if (isNonIndividualKycRoute && responseData) {
				if (responseData.type_of_user === "I") {
					window.location.href = process.env.PREACT_APP_KYC_URL;
				}

				if (responseData.ib_kyc_status === 4) {
					window.location.href = `${process.env.PREACT_APP_NAVIGATION_ENDPOINT}profile/basic-details`;
				}

				route(USER_ACCOUNT_TYPE_ACCESS_ROUTES[responseData?.type_of_user]);
			}
		});

		window.history.scrollRestoration = "manual";
	}, []);

	return (
		<div id="app">
			{userDetails ? (
				<Header key={userDetails} userDetails={userDetails} />
			) : (
				<Header userDetails="" />
			)}
			<div className="app-main-view">
				<main className={`${overidingClass}`}>
					<Router onChange={handleRoute} default={pageNotFoundRoute.path}>
						{[
							...portfolioRoutes,
							...sgbRoutes,
							...mediaRoutes,
							...nonIndividualProfileRoutes,
							...kycDetailsRoutes,
						].map((childRoute) => {
							return (
								<AsyncRoute
									path={childRoute.path}
									key={childRoute.path}
									getComponent={() =>
										import(`../routes/${childRoute.component.toString()}`).then(
											(module) => {
												return module.default;
											}
										)
									}
									userDetails={userDetails}
									loading={() => (
										<div className="loaderContainer">
											<Spinner />
										</div>
									)}
								/>
							);
						})}
						<Redirect
							path="/"
							replace={true}
							to={`${portFolioSummaryLandingRoute.path}`}
						/>
						<NotFound default />
					</Router>
				</main>
			</div>
			<Footer />
		</div>
	);
}

export default App;
