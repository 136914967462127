import Axios from "axios";
import Utils, {baseUrlGenerator} from "../../utils";
import { portfolioRoutes } from "../../alias/portfolioRoutes";
import { mediaRoutes } from "../../alias/mediaRoutes";
import { sgbRoutes } from "../../alias/sgbRoutes";

const UNAUTHENTICATED_ROUTES = [
	...portfolioRoutes,
	...mediaRoutes,
	...sgbRoutes,
].filter((childRoute) => !childRoute.authenticated);

const publicUrl = UNAUTHENTICATED_ROUTES.map((item) => item.path);
const isWithCredentials = process.env.PREACT_APP_ENV_NAME !== "local";
const { extractedBaseUrl } = baseUrlGenerator();

const checkEscapeUrls = () => {
	const rawWindowUrl = window.location.pathname.endsWith("/")
		? window.location.pathname.slice(0, -1)
		: window.location.pathname;
	return publicUrl.some((url) => url.includes(rawWindowUrl));
};

export default class NetworkLibrary {
	static get(endpoint, queryParamsMap, requestHeader, extraParams = {}) {
		return new Promise((resolve, reject) => {
			let headerObj = Object.assign(
				NetworkLibrary.getDefaultHeader(),
				requestHeader ? requestHeader : {}
			);

			let config = {
				withCredentials: isWithCredentials,
				headers: headerObj,
				params: queryParamsMap,
				...extraParams,
			};

			Axios.get(endpoint, config)
				.then((response) => {
					resolve(response);

					this.trackApiResponseIfError(endpoint, response);
				})
				.catch((error) => {
					if (
						[401, 403].includes(error.response.status) &&
						!checkEscapeUrls()
					) {
						return (window.location.href = `${extractedBaseUrl}/customer/login/`);
					}
					reject(error);
					// // observer.error(error);
					// this.trackApiResponseIfError(endpoint, error.response);
				})
				.then(() => {});
		});
	}

	static post(endpoint, requestBody, requestHeader) {
		// validateAndSetClientIdCookie();
		return new Promise((resolve, reject) => {
			let headerObj = Object.assign(
				NetworkLibrary.getDefaultHeader(),
				requestHeader ? requestHeader : {}
			);
			let config = { withCredentials: isWithCredentials, headers: headerObj };
			// let config = { headers: headerObj };
			Axios.post(endpoint, requestBody, config)
				.then((response) => {
					resolve(response);
					this.trackApiResponseIfError(endpoint, response);
				})
				.catch((error) => {
					if (
						[401, 403].includes(error.response.status) &&
						!checkEscapeUrls()
					) {
						return (window.location.href = `${extractedBaseUrl}/customer/login/`);
					}
					reject(error);
				})
				.then(() => {});
		});
	}

	static getDefaultHeader() {
		let headers = {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
			globalSessionId: Utils.getSessionCookie() || "",
		};
		Utils.getSessionCookie() !== undefined
			? (headers["session-token"] = Utils.getSessionCookie())
			: null;

		return headers;
	}

	static trackApiResponseIfError(endpoint, response) {
		if (
			response &&
			response.status &&
			response.status === 200 &&
			response.data &&
			response.data.success
		) {
			return;
		}
		let apiResponseCode =
			response && response.data && response.data.code ? response.data.code : 0;
		let httpResponseCode = response && response.status ? response.status : 0;
		// tracker.trackApiError(endpoint, apiResponseCode, httpResponseCode);
	}
}
