export const corporateKycRoute = {
	path: "/kyc/corporate-kyc",
	component: "corporate-kyc",
	componentName: "CorporateKyc",
	theme: "default",
	template: "home",
	authenticated: true,
};
export const hufKycRoute = {
	path: "/kyc/huf-kyc",
	component: "huf-kyc",
	componentName: "HufKyc",
	theme: "default",
	template: "home",
	authenticated: true,
};
export const llpKycRoute = {
	path: "/kyc/llp-kyc",
	component: "llp-kyc",
	componentName: "LlpKyc",
	theme: "default",
	template: "home",
	authenticated: true,
};
export const partnershipKycRoute = {
	path: "/kyc/partnership-kyc",
	component: "partnership-kyc",
	componentName: "PartnershipKyc",
	theme: "default",
	template: "home",
	authenticated: true,
};

export const kycDetailsRoutes = [
	corporateKycRoute,
	hufKycRoute,
	llpKycRoute,
	partnershipKycRoute,
];
