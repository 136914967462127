import { h } from "preact";
import style from "./style.scss";

const extractedBaseUrl =
	window.location.href.indexOf(".com") > -1
		? `${window.location.href.split(".com")[0]}.com`
		: window.location.href;
let isDomainTypePartner = false;
if (
	window.location.href.indexOf(".com") > -1 &&
	window.location.href.indexOf("www.indiabonds") < 0
) {
	isDomainTypePartner = true;
}

const Footer = () => (
	<div class="footer-base hide-footer" style="width: 100%; position: static;">
		<div class="container ib-footer-container">
			<div class="logo-row">
				<div className={`${style.logoHolder}`}>
					<a href={`${extractedBaseUrl}`}>
						<img
							loading="lazy"
							src="https://assets.indiabonds.com/react/ib-portfolio/india-bonds-white-logo.png"
							class="logo-style logo-style-footer"
							alt="India Bonds"
						/>
					</a>
					{isDomainTypePartner ? (
						<div className={`${style.partnerLogoClassParent}`}>
							<img
								className={`${style.partnerLogoClass}`}
								src="https://ib-prod-media.s3.ap-south-1.amazonaws.com/sbicapsec_60296303-1045-450d-a5bb-43a45ac23c03.png"
							/>
						</div>
					) : (
						""
					)}
				</div>

				<div class="footer-navs">
					<div class="row footer-row hidden-xs">
						{!isDomainTypePartner ? (
							<div class="col-md-4 footer-col">
								<a
									id="footer-about-us"
									title="About India Bonds"
									class="footer-anchor"
									href={`${extractedBaseUrl}/about-us`}
								>
									About Us
								</a>
							</div>
						) : (
							""
						)}

						<div class="col-md-4 footer-col">
							<a
								id="footer-explore"
								title="Explore"
								class="footer-anchor"
								href={`${extractedBaseUrl}/explore`}
							>
								Explore
							</a>
						</div>
						<div class="col-md-4 footer-col">
							<a
								id="footer-bond-directory"
								title="Bond Directory"
								class="footer-anchor"
								href={`${extractedBaseUrl}/bond-directory`}
							>
								Bond Directory
							</a>
						</div>
					</div>
					<div class="row footer-row hidden-xs">
						<div class="col-md-4 footer-col">
							<a
								title="Privacy Policy"
								class="footer-anchor"
								href={`${extractedBaseUrl}/privacy-policy`}
							>
								Privacy Policy
							</a>
						</div>
						<div class="col-md-4 footer-col">
							<a
								id="footer-how-it-works"
								title="How it works?"
								class="footer-anchor"
								href={`${extractedBaseUrl}/how-to-buy-bonds`}
							>
								How it works?
							</a>
						</div>
                        <div class="col-md-4 footer-col">
                            <a
                                id="footer-learn"
                                title="BondUNi"
                                class="footer-anchor"
                                href={`${extractedBaseUrl}/bonduni`}
                            >
                                BondUNi
                            </a>
                        </div>
					</div>
					<div class="row footer-row hidden-xs">
						<div class="col-md-4 footer-col">
							<a
								title="Terms &amp; Conditions"
								class="footer-anchor"
								href={`${extractedBaseUrl}/terms-and-conditions`}
							>
								Terms &amp; Conditions
							</a>
						</div>
						<div class="col-md-4 footer-col">
							<a
								id="footer-contact-us"
								title="Contact Us"
								class="footer-anchor"
								href={`${extractedBaseUrl}/contact-us`}
							>
								Contact Us
							</a>
						</div>
						<div class="col-md-4 footer-col">
							<a
								id="footer-faq"
								title="Frequently Asked Questions"
								class="footer-anchor"
								href={`${extractedBaseUrl}/frequently-asked-questions`}
							>
								FAQs
							</a>
						</div>
					</div>
					<div class="row footer-row hidden-xs">
						<div class="col-md-4 footer-col">
							<a
								title="Disclaimer"
								class="footer-anchor"
								href={`${extractedBaseUrl}/disclaimer`}
							>
								Disclaimer
							</a>
						</div>

						<div class="col-md-4 footer-col">
							<a
								id="footer-investor-grievance"
								title="Investor Grievance"
								class="footer-anchor"
								href="https://assets.indiabonds.com/Investor+Grievance+Mechanism_IndiaBonds.pdf"
							>
								Investor Grievance
							</a>
						</div>
						{!isDomainTypePartner ? (
							<div class="col-md-4 footer-col">
								<a
									id="careers"
									title="Careers"
									class="footer-anchor"
									href={`${extractedBaseUrl}/careers`}
								>
									Careers
								</a>
							</div>
						) : (
							""
						)}
					</div>
					<div class="row footer-row hidden-xs">
						<div class="col-md-4 footer-col">
							<a
								title="Regulatory Disclosure"
								class="footer-anchor"
								href="https://assets.indiabonds.com/regulatory_disclosure.pdf"
								target="_blank"
								rel="noreferrer"
							>
								Regulatory Disclosure
							</a>
						</div>
						<div class="col-md-4 footer-col">
							<a
								id="footer-investor-grievance"
								title="Investor Charter"
								class="footer-anchor"
								href="https://assets.indiabonds.com/Investor+Charter_IndiaBonds.pdf"
								target="_blank"
								rel="noreferrer"
							>
								Investor Charter
							</a>
						</div>
						<div class="col-md-4 footer-col">
							<a
								id="careers"
								title="Investor Complaints"
								class="footer-anchor"
								href="https://assets.indiabonds.com/Investor+Complaints.pdf"
								target="_blank"
								rel="noreferrer"
							>
								Investor Complaints
							</a>
						</div>
					</div>

					<div class="row visible-xs">
						<div class="col-xs-6 col-sm-6 mobile-navs">
							{!isDomainTypePartner ? (
								<a
									id="footer-about-us"
									title="About India Bonds"
									class="footer-anchor"
									href={`${extractedBaseUrl}/about-us`}
								>
									About Us
								</a>
							) : (
								""
							)}
							<a
								id="footer-explore"
								title="Explore"
								class="footer-anchor"
								href={`${extractedBaseUrl}/explore`}
							>
								Explore
							</a>
							<a
								id="footer-bond-directory"
								title="Bond Directory"
								class="footer-anchor"
								href={`${extractedBaseUrl}/bond-directory`}
							>
								Bond Directory
							</a>
							<a
								title="Privacy Policy"
								class="footer-anchor"
								href={`${extractedBaseUrl}/privacy-policy`}
							>
								Privacy Policy
							</a>
							<a
								id="footer-how-it-works"
								title="How it works?"
								class="footer-anchor"
								href={`${extractedBaseUrl}/how-to-buy-bonds`}
							>
								How it works?
							</a>
							<a
								id="footer-learn"
								title="BondUNi"
								class="footer-anchor"
								href={`${extractedBaseUrl}/bonduni`}
							>
								BondUNi
							</a>
							<a
								title="Terms & Conditions"
								class="footer-anchor"
								href={`${extractedBaseUrl}/terms-and-conditions`}
							>
								Terms & Conditions
							</a>
							<a
								id="footer-contact-us"
								title="Contact Us"
								class="footer-anchor"
								href={`${extractedBaseUrl}/contact-us`}
							>
								Contact Us
							</a>
						</div>
						<div class="col-xs-6 col-sm-6 mobile-navs">
							<a
								id="footer-faq"
								title="Frequently Asked Questions"
								class="footer-anchor"
								href={`${extractedBaseUrl}/frequently-asked-questions`}
							>
								FAQs
							</a>
							<a
								title="Disclaimer"
								class="footer-anchor"
								href={`${extractedBaseUrl}/disclaimer`}
							>
								Disclaimer
							</a>
							<a
								title="Investor Grievance"
								class="footer-anchor"
								href="https://assets.indiabonds.com/Investor+Grievance+Mechanism_IndiaBonds.pdf"
								target="_blank"
								rel="noreferrer"
							>
								Investor Grievance
							</a>
							{!isDomainTypePartner ? (
								<a
									id="careers"
									title="Careers"
									class="footer-anchor"
									href={`${extractedBaseUrl}/careers`}
								>
									Careers
								</a>
							) : (
								""
							)}
							<a
								title="Regulatory Disclosure"
								class="footer-anchor"
								href="https://assets.indiabonds.com/regulatory_disclosure.pdf"
								target="_blank"
								rel="noreferrer"
							>
								Regulatory Disclosure
							</a>
							<a
								title="Investor Charter"
								class="footer-anchor"
								href="https://assets.indiabonds.com/Investor+Charter_IndiaBonds.pdf"
								target="_blank"
								rel="noreferrer"
							>
								Investor Charter
							</a>
							<a
								title="Investor Complaints"
								class="footer-anchor"
								href="https://assets.indiabonds.com/Investor+Complaints.pdf"
								target="_blank"
								rel="noreferrer"
							>
								Investor Complaints
							</a>
						</div>
					</div>
				</div>

				<div class="footer-socials">
					<div class="follow-title">Follow Us</div>
					<div class="social-icon-wrapper">
						<a
							href="https://www.facebook.com/IndiaBondsOfficial/"
							target="_blank"
							rel="nofollow noreferrer"
						>
							<img
								loading="lazy"
								src="https://assets.indiabonds.com/react/ib-portfolio/awesome-facebook-square.svg"
								alt="facebook logo"
							/>
						</a>
						<a
							href="https://twitter.com/india_bonds"
							target="_blank"
							rel="nofollow noreferrer"
						>
							<img
								loading="lazy"
								src="https://assets.indiabonds.com/react/ib-portfolio/awesome-twitter-square.svg"
								alt="twitter logo"
							/>
						</a>
						<a
							href="https://www.linkedin.com/company/indiabonds"
							target="_blank"
							rel="nofollow noreferrer"
						>
							<img
								loading="lazy"
								src="https://assets.indiabonds.com/react/ib-portfolio/awesome-linkedin.svg"
								alt="linkedin logo"
							/>
						</a>
						<a
							href="https://www.instagram.com/indiabonds/"
							target="_blank"
							rel="nofollow noreferrer"
						>
							<img
								loading="lazy"
								src="https://assets.indiabonds.com/react/ib-portfolio/instagram.svg"
								alt="instagram logo"
							/>
						</a>
						<a
							href="https://www.youtube.com/channel/UC-DE1oUSTBMXJlp2IyFa7Ug"
							target="_blank"
							rel="nofollow noreferrer"
						>
							<img
								loading="lazy"
								src="https://assets.indiabonds.com/react/ib-portfolio/youtube-white.svg"
								alt="youtube logo"
							/>
						</a>
					</div>
					<div class="cin-info">
						CIN: U67100MH2008PTC178990 <span class="visiblelg">|</span>{" "}
						<br class="visible-xs" />
						SEBI Registration No.: INZ000311637 <span class="visiblelg">
							|
						</span>{" "}
						<br class="visible-xs" />
						NSE Member ID - Debt Segment: 90316 <span class="visiblelg">
							|
						</span>{" "}
						<br class="visible-xs" />
						BSE Member ID - Debt Segment: 6811
					</div>
					<div class="address-info">
						Registered Address: 605, 6th Floor, Windsor, Off CST Road, Kalina,
						Santacruz - (East), Mumbai – 400 098.
						<span class="visiblelg" />
					</div>
					<div class="copyright-info">
						© 2020-2022{" "}
						<a href="https://www.indiabonds.com/" class="footer-link-color">
							India Bond
						</a>{" "}
						Pvt Ltd.
					</div>
				</div>
			</div>

			<div class="footer-line" />
			<div class="row marginLR0">
				<p class="footer-disclaimer">
					Disclaimer : Investments in debt securities/ municipal debt
					securities/ securitised debt instruments are subject to risks
					including delay and/ or default in payment. Read all the offer related
					documents carefully.
				</p>
			</div>
			{!isDomainTypePartner ? (
				<div class="row">
					<div class="col-xs-12">
						<ul class="footer-bond-links">
							<li class="pad-left-0">
								<p class="footer-offering-title ">Offerings:</p>
							</li>
							<li>
								<a
									id="footer-offering-high-yield-bonds"
									href={`${extractedBaseUrl}/high-yield-bonds`}
									title="High Yield Bonds"
								>
									High Yield Bonds
								</a>
							</li>
							<li class="pipe-seperator">|</li>
							<li>
								<a
									id="footer-offering-tax-free-bonds"
									href={`${extractedBaseUrl}/tax-free-bonds`}
									title="Tax Free Bonds"
								>
									Tax Free Bonds
								</a>
							</li>
							<li class="pipe-seperator">|</li>
							<li>
								<a
									id="footer-offering-bank-bonds"
									href={`${extractedBaseUrl}/bank-bonds`}
									title="Bank Bonds"
								>
									Bank Bonds
								</a>
							</li>
							<li class="pipe-seperator">|</li>
							<li>
								<a
									id="footer-offering-government-bonds"
									href={`${extractedBaseUrl}/government-bonds`}
									title="Government Bonds"
								>
									Government Bonds
								</a>
							</li>
							<li class="pipe-seperator">|</li>

							<li>
								<a
									id="footer-offering-capital-gain-bonds"
									href={`${extractedBaseUrl}/capital-gain-bonds`}
									title="Capital Gain Bonds"
								>
									Capital Gain Bonds
								</a>
							</li>
							<li class="pipe-seperator">|</li>

							<li>
								<a
									id="footer-offering-sovereign-gold-bonds"
									href={`${extractedBaseUrl}/sgb/landing`}
									title="Sovereign Gold Bonds"
								>
									Sovereign Gold Bonds
								</a>
							</li>
							<li class="pipe-seperator">|</li>

							{/* <li>
								<a
									id="footer-offering-debt-pms"
									href={`${extractedBaseUrl}/debt-pms`}
									title="Debt PMS"
								>
									Debt PMS
								</a>
							</li> */}
							{/* <li class="pipe-seperator">|</li> */}

							<li>
								<a
									id="footer-offering-bond-ipo"
									href={`${extractedBaseUrl}/public-issue`}
									title="Bonds IPO"
								>
									Bond IPO
								</a>
							</li>
							<li class="pipe-seperator">|</li>

							<li>
								<a
									id="footer-offering-bond-calculator"
									href={`${extractedBaseUrl}/bond-calculator`}
									title="Bond Pricer &amp; YTM Calculator"
								>
									Bond Calculator
								</a>
							</li>
							<li class="pipe-seperator">|</li>
							<li>
								<a
									id="footer-offering-form-15g-15h"
									href={`${extractedBaseUrl}/form-15g-15h`}
									title="Form 15G and 15H"
								>
									Form 15G / 15H{" "}
								</a>
							</li>
						</ul>
					</div>
				</div>
			) : (
				""
			)}
		</div>
	</div>
);

export default Footer;
