export const depositoryNameOptions = [
	{
		label: "NSDL",
		value: "NSDL",
	},
	{
		label: "CDSL",
		value: "CDSL",
	},
];

export const dpAccountTypeOptions = [
	{
		label: "Sole",
		value: "Sole",
	},
	{
		label: "Joint",
		value: "Joint",
	},
];

export const genderOptions = [
	{
		label: "Male",
		value: "Male",
	},
	{
		label: "Female",
		value: "Female",
	},
	{
		label: "Others",
		value: "Others",
	},
];

export const yesNoOptions = [
	{
		label: "Yes",
		value: "Yes",
	},
	{
		label: "No",
		value: "No",
	},
];

export const userAccountTypeOptions = [
	{
		label: "Corporate",
		value: "C",
	},
	{
		label: "HUF (Hindu Undivided Family)",
		value: "H",
	},
	{
		label: "LLP (Limited Liability Partnership)",
		value: "L",
	},
	{
		label: "Partnership",
		value: "P",
	},
	{
		label: "Individual",
		value: "I",
	},
];

export const PROFILE_TYPE_MAPPING = {
	C: "Corporate",
	H: "HUF (Hindu Undivided Family)",
	L: "LLP (Limited Liability Partnership)",
	P: "Partnership",
	I: "Individual",
};

export const ageGroupOptions = [
	"Below 30",
	"31 - 40",
	"41 - 50",
	"51 - 60",
	"Above 60",
];

export const REGEX = {
	DP_ID_REGEX: /^[a-zA-Z0-9]+$/,
	CLIENT_ID_REGEX: /^\d{8}$/,
	BENEFICIARY_ID_REGEX: /^[1-9]\d*$/,
	EMAIL_REGEX: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
};

export const KYC_REDIRECTION_ROUTES_CONFIG = {
	I: process.env.PREACT_APP_KYC_URL,
	C: `${process.env.PREACT_APP_NAVIGATION_ENDPOINT}kyc/corporate-kyc`,
	H: `${process.env.PREACT_APP_NAVIGATION_ENDPOINT}kyc/huf-kyc`,
	L: `${process.env.PREACT_APP_NAVIGATION_ENDPOINT}kyc/llp-kyc`,
	P: `${process.env.PREACT_APP_NAVIGATION_ENDPOINT}kyc/partnership-kyc`,
};

export const KYC_CHECKLIST_PDF_URL = {
	C: `${process.env.PREACT_APP_KYC_CHECKLIST_URL}IndiaBonds Corporate KYC Checklist.pdf`,
	H: `${process.env.PREACT_APP_KYC_CHECKLIST_URL}IndiaBonds HUF KYC Checklist.pdf`,
	L: `${process.env.PREACT_APP_KYC_CHECKLIST_URL}IndiaBonds LLP KYC Checklist.pdf`,
	P: `${process.env.PREACT_APP_KYC_CHECKLIST_URL}IndiaBonds Partnership KYC Checklist.pdf`,
};

export const USER_ACCOUNT_TYPE_ACCESS_ROUTES = {
	I: process.env.PREACT_APP_KYC_URL,
	C: `/kyc/corporate-kyc`,
	H: `/kyc/huf-kyc`,
	L: `/kyc/llp-kyc`,
	P: `/kyc/partnership-kyc`,
};

export const RELATIONSHIP_WITH_USER = [
	{
		label: "Karta of HUF",
		value: "Karta of HUF",
	},
	{
		label: "Director of Company",
		value: "Director of Company",
	},
	{
		label: "Authorized Person of Company",
		value: "Authorized Person of Company",
	},
	{
		label: "Partner of LLP / Partnership Firm",
		value: "Partner of LLP / Partnership Firm",
	},
	{
		label: "Authorized Person of LLP / Partnership Firm",
		value: "Authorized Person of LLP / Partnership Firm",
	},
	{
		label: "Others",
		value: "Others",
	},
];
