export const sgbLandingRoute = {
	path: "/sgb/landing",
	component: "sgb-landing",
	componentName: "Sgb",
	theme: "sgb",
	template: "home",
	authenticated: false,
};
export const sgbOrderDetailsRoute = {
	path: "/sgb/order-details",
	component: "sgb-order-details",
	componentName: "Sgb",
	theme: "sgb",
	template: "home",
	authenticated: true,
};
export const sgbMakePaymentRoute = {
	path: "/sgb/make-payment",
	component: "sgb-make-payment",
	componentName: "Sgb",
	theme: "sgb",
	template: "home",
	authenticated: true,
};
export const sgbOrderReceivedRoute = {
	path: "/sgb/order-received",
	component: "sgb-order-received",
	componentName: "Sgb",
	theme: "sgb",
	template: "home",
	authenticated: true,
};

export const sgbRoutes = [
	sgbLandingRoute,
	sgbOrderDetailsRoute,
	sgbMakePaymentRoute,
	sgbOrderReceivedRoute,
];
