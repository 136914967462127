export const profileRoute = {
	path: "/profile/:activeTab?",
	component: "non-individual-profile",
	componentName: "Profile",
	theme: "default",
	template: "home",
	authenticated: true,
};

export const nonIndividualProfileRoutes = [profileRoute];
