import { useRef } from "react";
import style from "./style.scss";
import { useEffect } from "preact/hooks";

const Modal = ({
	isOpen,
	closeModal,
	children,
	isOuterClick = true,
	fullScreen = false,
	lightOverlay = false,
	showCrossArrow = true,
	bottomSheet = true,
}) => {
	const modalRef = useRef(null);

	const closeOnOverlayClick = (e) => {
		if (
			isOuterClick &&
			modalRef.current &&
			!modalRef.current.contains(e.target)
		) {
			closeModal();
		}
	};

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
	}, [isOpen]);

	if (!isOpen) return null;

	return (
		<div
			class={`${style.modalOverlay} ${
				lightOverlay ? style.lightOverlay : ""
			} ${fullScreen && style.fullScreenOverlay}`}
			onClick={closeOnOverlayClick}
		>
			<div
				ref={modalRef}
				role="dialog"
				class={`${style.modalContent} ${fullScreen &&
					style.fullScreen} ${bottomSheet && style.mobileBottomSheet}`}
			>
				{showCrossArrow && (
					<div class={style.closeButton} onClick={closeModal}>
						×
					</div>
				)}
				{children}
			</div>
		</div>
	);
};

export default Modal;
