export const mediaUpdatesRoute = {
    path: '/media-updates',
    component: 'media-updates',
    componentName: 'MediaUpdates',
    theme: 'default',
    template: 'home',
    authenticated: false,
}

export const mediaRoutes = [
    mediaUpdatesRoute,
];
