export const portFolioSummaryLandingRoute = {
    path: '/dashboard',
    component: 'portfolio-landing',
    componentName: 'Portfolio',
    theme: 'default',
    template: 'home',
    authenticated: true
};

export const portFolioDashboardRoute = {
    path: '/portfolio/dashboard',
    component: 'portfolio-landing',
    componentName: 'Portfolio',
    theme: 'default',
    template: 'home',
    authenticated: true
};

export const portFolioUploadRoute = {
    path: '/portfolio/upload',
    component: 'portfolio-upload',
    componentName: 'Portfolio',
    theme: 'default',
    template: 'home',
    authenticated: true
};

export const portfolioRoutes = [
    portFolioSummaryLandingRoute,
    portFolioDashboardRoute,
    portFolioUploadRoute
];
