export default class BeEndpoints {
	//general
	static BASE_BE_URL = process.env.PREACT_APP_BE_ENDPOINT_BASE_URL;
	static RAW_BASE_BE_URL = process.env.PREACT_APP_BE_ENDPOINT_BASE_URL;
	static NAVIGATION_URL = process.env.PREACT_APP_NAVIGATION_ENDPOINT;
	static NEW_BASE_BE_URL = process.env.PREACT_APP_BE_NEW_ENDPOINT_BASE_URL;
	static BASE_DMZ_URL = process.env.PREACT_APP_BE_ENDPOINT_DMZ_URL;
	static POST_DMZ_UPLOAD_FILE = "api/dmz/upload_file";
	static USER_DETAILS = "v1/identities/get-user-details/";

	// Portfolio APIs
	static GET_USER_DETAILS = "v2/portfolio/get-user-details";
	static GET_USER_PORTFOLIO_SUMMARY_DETAILS = "v2/portfolio/summary-details";
	static GET_USER_PORTFOLIO_WHATS_HOT_DATA = "v2/portfolio/whats-hot-bonds";
	static DELETE_USER_PORTFOLIO_ORDER = "v2/portfolio/delete-order/";
	static SAVE_NOTIFICATION_TOKEN = "devices";
	static GET_DMZ_TOKEN = "portfolio/get-dmz-token";

	// Media APIs
	static GET_MEDIA_LINKS = "media-link";

	// Profile APIs
	static PROFILE_PREFIX = "/non-ind-kyc/external/transaction/";
	static CHANGE_PASSWORD = "v1/identities/non-individual-password-update/";
	static GET_STATUS = "/non-ind-kyc/external/status";
	static GET_DOCUMENTS = "/documents/all";
	static UPLOAD_DOCUMENT = "/documents/other_documents";
	static GET_PROFILE_DETAILS = "/profile_details";
	static GET_AUTHORIZED_PERSON = "/authorized_person_details";
	static GET_PRESIGNED_URL_DOCUMENT = "/non-ind-kyc/external/presigned_url";
	static GET_UBO = "/annexure_details";
	static GET_COMMUNICATION_DETAILS = "/communication_details";
	static GET_CITY_STATE_FROM_PINCODE =
		"/communication_details/fetchPincodeDetails";
	static GET_BASIC_DETAILS = "/client_details";
	static GET_NAME_FROM_PAN = "/client_details/pan_fetch";
	static GET_DEMAT_ACCOUNTS = "/demat_account";
	static GET_BANK_ACCOUNTS = "/bank_account";
	static GET_BANK_FROM_IFSC = "/bank_account/ifsc";
	static GET_METADATA = "/metadata";
	static GET_CAPTCHA = "api/captcha/";
	static GET_QUESTIONS = "customer/profile/non-individual-profile-details/";
	static GET_FILE_UPLOAD_INIT_TOKEN = "/file_upload/init";
	static MAKE_BANK_DEFAULT = "/bank_account/default";
	static DELETE_BANK = "/bank_account/delete";
	static ADD_BANK = "/bank_account/upload";
	static MAKE_DEMAT_DEFAULT = "/demat_account/default";
	static DELETE_DEMAT = "/demat_account/delete";
	static GET_DEPOSITORY_NAME = "/demat_account/depository";
	static ADD_DEMAT = "/demat_account";
	static GENERATE_OTP_USER = "v1/identities/generate-otp-user/";
	static VALIDATE_OTP_USER = "v1/identities/validate-otp-user/";
	static GENERATE_NEW_UPDATE_OTP = "v1/identities/generate-otp-update/";
	static VALIDATE_NEW_UPDATE_OTP = "v1/identities/validate-otp-and-save/";
	static CHANGE_USER_TYPE =
		"v1/identities/validate-otp-and-save-non-individual/";
}
