import BeEndpoints from "../endpoints/be-endpoints/";
import NetworkLibrary from "../api/NetworkLibrary";
import { map } from "rxjs/internal/operators/map";
import { getCookie } from "../../utils";

export function getUserDetails() {
	let authId = getCookie(process.env.PREACT_APP_SESSION_COOKIE);
	let csrftoken = getCookie("csrftoken");
	if (process.env.PREACT_APP_ENV_NAME === "local") {
		authId = process.env.PREACT_APP_AUTH_TOKEN;
	}
	let url = BeEndpoints.BASE_BE_URL + BeEndpoints.USER_DETAILS;
	let requestBody = {};
	let requestHeader = { globalSessionId: authId, "X-CSRFToken": csrftoken };

	return NetworkLibrary.get(url, requestBody, requestHeader);
}

export function getSubdomain() {
	let subdomain = getCookie(process.env.PREACT_APP_PARTNER_DOMAIN);
	return subdomain;
}
